






































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { ProfileModule } from "@/store/profile-module";
import { FixturesApi, Fixture } from "@/store/fixtures-api";
import FixtureItem from "./FixtureItem.vue";
import FixtureForm from "./FixtureForm.vue";
import FixtureSubscriptionSignup from "./FixtureSubscriptionSignup.vue";
import FixtureSubscriptionVerify from "./FixtureSubscriptionVerify.vue";

@Component({
    components: {
        FixtureItem,
        FixtureForm,
        FixtureSubscriptionSignup,
        FixtureSubscriptionVerify,
    },
})
export default class Fixtures extends Vue {
    @Ref("edit") readonly edit!: FixtureForm;
    @Ref() readonly signup!: FixtureSubscriptionSignup;
    @Ref() readonly verify!: FixtureSubscriptionVerify;

    fixtureStore = new FixturesApi();
    profileStore = getModule(ProfileModule);

    fixtures: Fixture[] = [];
    loading = false;
    range = 1;

    get isAdmin() {
        return this.profileStore.profile.admin;
    }

    get downloadUrl() {
        return `/api/fixtures/download?range=${this.range}`;
    }

    mounted() {
        this.get();

        if (this.$route.query.email && this.$route.query.token) {
            this.verify.open(this.$route.query.email as string, this.$route.query.token as string);
        }
    }

    get() {
        this.loading = true;
        this.fixtureStore
            .getAll({ range: this.range })
            .then((res) => (this.fixtures = res.data))
            .then(() => (this.loading = false));
    }

    openSignup() {
        this.signup.open();
    }

    canEdit(teamId: number) {
        return this.profileStore.profile.admin || this.profileStore.profile.teams?.includes(teamId);
    }

    openEdit(fixture: Fixture) {
        this.edit.open(fixture, fixture.teamId);
    }

    @Watch("range")
    onRangeChanged() {
        this.get();
    }
}
